// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-attributions-js": () => import("./../src/pages/attributions.js" /* webpackChunkName: "component---src-pages-attributions-js" */),
  "component---src-pages-founders-js": () => import("./../src/pages/founders.js" /* webpackChunkName: "component---src-pages-founders-js" */),
  "component---src-pages-founders-dc-js": () => import("./../src/pages/founders/DC.js" /* webpackChunkName: "component---src-pages-founders-dc-js" */),
  "component---src-pages-founders-jc-js": () => import("./../src/pages/founders/JC.js" /* webpackChunkName: "component---src-pages-founders-jc-js" */),
  "component---src-pages-founders-pm-js": () => import("./../src/pages/founders/PM.js" /* webpackChunkName: "component---src-pages-founders-pm-js" */),
  "component---src-pages-founders-rc-js": () => import("./../src/pages/founders/RC.js" /* webpackChunkName: "component---src-pages-founders-rc-js" */),
  "component---src-pages-founders-rs-js": () => import("./../src/pages/founders/RS.js" /* webpackChunkName: "component---src-pages-founders-rs-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

